import {CircleLoader} from "react-spinners";
import React from "react";

export const firstSpinner = (height) =>
    <>
        <div className="d-flex justify-content-center align-items-center"
             style={{height: height != null ? height : "50vh"}}>
            <CircleLoader
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                size="80px"
                color="#fb324f"
            />
        </div>
    </>;


export function getFirstPageToDisplay() {
    return '/'
}

export function isExistInArray(array, ArrayValue) {

    for (let index = 0; index < ArrayValue.length; index++) {
        const element = ArrayValue[index];
        for (let index = 0; index < array.length; index++) {
            if (element === array[index])
                return true
        }
    }
    return false
}