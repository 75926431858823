import {SET_AUDIO_VOLUME, SET_PLAYLIST} from "../actions/types";

const initialState = {volume: 0.5, playlist: [], playlistInfo: {}};

export default function song(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_AUDIO_VOLUME:
            return {...state, volume: payload};
        case SET_PLAYLIST:
            return {...state, playlist: payload.playlist, playlistInfo: payload.playlistInfo};
        default:
            return state;
    }
}
