import {Constant} from '../helpers/const';

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const SET_PROFILE = "SET_PROFILE";
export const SET_SETTING = "SET_SETTING";

export const CHAT_OPEN = Constant.WS_CHAT_PREFIX + "::OPEN";
export const CHAT_CLOSED = Constant.WS_CHAT_PREFIX + "::CLOSED";
export const CHAT_MESSAGE = Constant.WS_CHAT_PREFIX + "::MESSAGE";
export const CHAT_BROKEN = Constant.WS_CHAT_PREFIX + "::BROKEN";
export const CHAT_BEGIN_RECONNECT = Constant.WS_CHAT_PREFIX + "::BEGIN_RECONNECT";
export const CHAT_RECONNECT_ATTEMPT = Constant.WS_CHAT_PREFIX + "::RECONNECT_ATTEMPT::BROKEN";
export const CHAT_RECONNECTED = Constant.WS_CHAT_PREFIX + "::RECONNECTED";
export const CHAT_ERROR = Constant.WS_CHAT_PREFIX + "::ERROR";

export const WEBSOCKET_OPEN = Constant.WS_PREFIX + "::OPEN";
export const WEBSOCKET_CLOSED = Constant.WS_PREFIX + "::CLOSED";
export const WEBSOCKET_MESSAGE = Constant.WS_PREFIX + "::MESSAGE";
export const WEBSOCKET_BROKEN = Constant.WS_PREFIX + "::BROKEN";
export const WEBSOCKET_BEGIN_RECONNECT = Constant.WS_PREFIX + "::BEGIN_RECONNECT";
export const WEBSOCKET_RECONNECT_ATTEMPT = Constant.WS_PREFIX + "::RECONNECT_ATTEMPT::BROKEN";
export const WEBSOCKET_RECONNECTED = Constant.WS_PREFIX + "::RECONNECTED";
export const WEBSOCKET_ERROR = Constant.WS_PREFIX + "::ERROR";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_AUDIO_VOLUME = "SET_AUDIO_VOLUME";
export const SET_PLAYLIST = "SET_PLAYLIST";
