import './App.scss';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/main.css';
import 'jquery-nice-select/css/nice-select.css'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import './assets/css/swiper.css'


import React, {Component, Suspense} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {firstSpinner} from './usefulFunction';
// Containers
const DefaultLayout = React.lazy(() => import('./AppLayout'));

// Pages
const Page404 = React.lazy(() => import('./pages/error/Page4040'));
const Page500 = React.lazy(() => import('./pages/error/Page500'));


class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Suspense fallback={firstSpinner()}>
            <Routes>
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </BrowserRouter>

      </>
    );
  }
}
export default App;