import React from 'react';
import ReactDOM from 'react-dom';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import store from "./store";
import {getTranslations} from "./actions/i18n";
import {getSettings} from "./actions/setting";

import {StyledEngineProvider} from '@mui/material/styles';
import reportWebVitals from "./reportWebVitals";
import {PrimeReactProvider} from "primereact/api";

store.dispatch(getTranslations());
store.dispatch(getSettings());

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <PrimeReactProvider>
                    <App/>
                </PrimeReactProvider>
            </StyledEngineProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
